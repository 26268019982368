import React, { useState, useEffect, useContext, useCallback } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import AccessTime from '@material-ui/icons/AccessTime';

// Material UI components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// Serendipity components
import {
    GlucoseGraph,
    BGCard,
    DataSourceSelector
} from 'components/Serendipity';

import { useInterval } from 'hooks/useIntervalHook';

// Auth Context
import { AuthContext } from 'lib/Auth';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import { getEntries, getDeviceStatus } from 'lib/nightscout';
import { getDexcomEntries, getFormattedEntries } from 'lib/dexcom';

// import deviceStatus from 'device-status';
const useStyles = makeStyles(styles);

export default function Dashboard(props) {
    const classes = useStyles();

    const { userPreferences, updateUserContext } = useContext(AuthContext);
    const { nightscout, serendipity } = userPreferences;
    const {
        nsRefreshInterval,
        base_url,
        BG_HIGH,
        BG_LOW,
        BG_TARGET_TOP,
        BG_TARGET_BOTTOM
    } = nightscout;

    const { machineHostname } = serendipity;

    const userUrl = machineHostname ? machineHostname : base_url;

    // Set up initial state for the dashboard.
    const [entries, setEntries] = useState([]);

    const DEFAULT_REFRESH_INTERVAL = 5 * 60 * 1000;
    // Prior day entries.
    const [priorDayEntries, setPriorDayEntries] = useState([]);

    const [fetchingEntries, setFetchingEntries] = useState(true);

    const [fetchingPriorEntries, setFetchingPriorEntries] = useState(true);

    const [chartData, setChartData] = useState({
        data: {
            series: [
                [
                    { x: Date.now() - 3 * 60 * 60 * 1000, y: 0 },
                    { x: Date.now(), y: 0 }
                ],
                [
                    { x: Date.now() - 3 * 60 * 60 * 1000, y: 0 },
                    { x: Date.now(), y: 0 }
                ]
            ],
            labels: [],
            targetLow: BG_TARGET_BOTTOM,
            targetHigh: BG_TARGET_TOP
        }
    });

    const [latestEntry, setLatestEntry] = useState({
        sgv: '',
        trend: '',
        direction: '',
        mills: ''
    });

    const [deviceStatus, setDeviceStatus] = useState([]);

    const [pumpStatus, setPumpStatus] = useState([]);

    const [refreshInterval, setNSRefreshInterval] = useState(
        DEFAULT_REFRESH_INTERVAL
    );

    const [lastFetchTime, setLastFetchTime] = useState('Fetching...');
    const [bgThresholdColor, setBgThresholdColor] = useState('info');

    const [chartDataSource, setChartDataSource] = useState('NS');

    useInterval(async () => {
        getEntries();
    }, refreshInterval);

    // Using Dexcom API.
    const getEntries = useCallback(async () => {
        const entries = await getDexcomEntries(nightscout);

        // format entries with proper date and time
        const formattedData = await getFormattedEntries(entries);

        if (formattedData.length > 0) {
            setEntries(formattedData);
            setLastFetchTime(
                new Date(
                    new Date().getTime() + nsRefreshInterval
                ).toLocaleTimeString()
            );
            setFetchingEntries(false);
        } else {
            setFetchingEntries(false);
        }
    }, []);

    useEffect(() => {
        getEntries();
    }, [getEntries]);

    const handleNSContextUpdate = (key, val) => {
        const updatedNSContext = nightscout;
        updatedNSContext[key] = val;
        updateUserContext('nightscout', updatedNSContext);
    };

    const handleNSIntervalSelect = (event) => {
        const updatedInterval = event.target.value;
        handleNSContextUpdate('nsRefreshInterval', updatedInterval);
        setNSRefreshInterval(updatedInterval);
    };

    const handleDataSourceSelect = (event) => {
        const { value } = event.target;
        setChartDataSource(value);
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4} lg={3}>
                            <BGCard
                                loading={fetchingEntries}
                                data={entries}
                                updateTime={lastFetchTime}
                                bgThresholdColor={bgThresholdColor}
                                targetHigh={BG_TARGET_TOP}
                                targetLow={BG_TARGET_BOTTOM}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8} lg={8}>
                            <GlucoseGraph
                                data={entries}
                                loading={fetchingEntries}
                                targetHigh={BG_TARGET_TOP}
                                targetLow={BG_TARGET_BOTTOM}
                                handleDataSourceSelect={handleDataSourceSelect}
                                chartDataSource={chartDataSource}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}
