import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Serendipity components
import { DataSourceSelector } from 'components/Serendipity';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();
    const { rtlActive, handleDataSourceSelect, chartDataSource } = props;

    const wrapper = classNames({
        [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
        [classes.managerClasses]: true
    });

    // Todo:  Add toggle handlers here.
    return (
        <div className={wrapper}>
            <div className={managerClasses}>
                <DataSourceSelector
                    handleDataSourceSelect={handleDataSourceSelect}
                    chartDataSource={chartDataSource}
                />
            </div>
        </div>
    );
}

HeaderLinks.propTypes = {
    rtlActive: PropTypes.bool
};
