import React, { useEffect, useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

import { useInterval } from 'hooks/useIntervalHook';

const useStyles = makeStyles(styles);

export default function BGCard(props) {
    const classes = useStyles();

    const DEFAULT_ENTRY = {
        x: new Date(),
        y: 0,
        raw: {
            Value: 0,
            Trend: 'FLAT'
        }
    };

    const TREND = {
        FLAT: 'FLAT',
        UP: 'UP',
        DOUBLEUP: 'DOUBLEUP',
        DOWN: 'DOWN',
        SINGLEDOWN: 'SINGLEDOWN',
        FORTYFIVEUP: 'FORTYFIVEUP',
        FORTYFIVEDOWN: 'FORTYFIVEDOWN',
        SINGLEUP: 'SINGLEUP'
    };

    const [latestEntry, setLatestEntry] = useState(DEFAULT_ENTRY);
    const [changePercentage, setChangePercentage] = useState(0);
    const [timeAgo, setTimeAgo] = useState('Update 0 minutes ago');

    // https://forum.tudiabetes.org/t/dexcom-trend-arrows/88223
    // https://mui.com/material-ui/material-icons/?query=arrow
    const getIconComponent = (entry) => {
        let arrowComponent;

        switch (entry.raw.Trend.toUpperCase()) {
            case TREND.FLAT:
                arrowComponent = 'arrow_forward';
                break;
            case TREND.SINGLEUP:
                arrowComponent = 'arrow_upward';
                break;
            case TREND.DOUBLEUP:
                arrowComponent = 'arrow_outward';
                break;
            case TREND.DOWN:
                arrowComponent = 'arrow_downward';
                break;
            case TREND.SINGLEDOWN:
                arrowComponent = 'arrow_downward';
                break;
            case TREND.DOUBLDOWN:
                arrowComponent = 'KeyboardDoubleArrowDown';
                break;
            case TREND.FORTYFIVEUP:
                arrowComponent = 'trending_up';
                break;
            case TREND.FORTYFIVEDOWN:
                arrowComponent = 'trending_down';
                break;
            default:
                arrowComponent = 'help_outline';
                break;
        }

        return <Icon>{arrowComponent}</Icon>;
    };

    const getChangePercentage = (priorEntry, currentEntry) => {
        const priorValue = priorEntry.y;
        const currentValue = currentEntry.y;

        const changePercentage =
            ((currentValue - priorValue) / priorValue) * 100;

        return changePercentage.toFixed(2);
    };

    const getElapsedTime = (entry) => {
        // Get the time between the latest entry and now
        const start = new Date(entry.x);
        const endTime = Date.now();

        // Millis between readings
        const elapsed = (endTime - start) / 1000;
        const elapsedHours = Math.floor(elapsed / 3600);
        const elapsedMinutes = Math.ceil(elapsed / 60);

        let elapsedTime = '';

        if (elapsedHours > 0 && elapsedMinutes > 0) {
            elapsedTime =
                'Updated ' +
                elapsedHours +
                ' hours and ' +
                elapsedMinutes +
                ' minutes ago';
        } else if (elapsedHours === 0 && elapsedMinutes >= 0) {
            elapsedTime = 'Updated ' + elapsedMinutes + ' minutes ago';
        } else {
            elapsedTime = 'Updated ' + elapsedMinutes + ' minutes ago';
        }

        return elapsedTime;
    };

    const setCardContext = () => {
        const entries = props.data;

        if (entries.length === 0) {
            setLatestEntry(DEFAULT_ENTRY);
            return;
        } else {
            // Get the latest entry
            const currentEntry = entries[0];
            // Get the prior to last entry
            const priorEntry = entries[1];
            setTimeAgo(getElapsedTime(currentEntry));
            setLatestEntry(currentEntry);
            setChangePercentage(getChangePercentage(priorEntry, currentEntry));
        }
    };

    useEffect(() => {
        setCardContext();
    }, [props.data]);

    useInterval(() => {
        setCardContext();
    }, 5000);

    return (
        <div>
            <Card>
                <CardHeader style={{ paddingBottom: '0' }}>
                    <div style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                        <span
                            style={{
                                textAlign: 'left',
                                fontSize: '20px',
                                fontWeight: '600'
                            }}
                        >
                            Current Glucose
                        </span>
                    </div>
                </CardHeader>
                <CardBody
                    style={{
                        height: '350px',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <GridContainer
                        style={{
                            height: '250px',
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <GridItem>
                            <div className={classes.BGCardIconContainer}>
                                <span className={classes.BGCardIcon}>
                                    {getIconComponent(latestEntry)}
                                </span>
                            </div>
                        </GridItem>
                        <GridItem>
                            <span className={classes.BGCardCurrentReading}>
                                {latestEntry.y}
                            </span>
                        </GridItem>
                        <GridItem>
                            <span className={classes.BGCardUnitsLabel}>
                                mg/dL
                            </span>
                        </GridItem>
                        <GridItem>
                            <div className={classes.BGCardIconContainer}>
                                <span className={classes.BGCardReadingTrend}>
                                    {changePercentage}%
                                </span>
                            </div>
                        </GridItem>
                        <GridItem>
                            <span className={classes.BGCardUnitsLabel}>
                                {timeAgo}
                            </span>
                        </GridItem>
                        <GridItem>
                            <span className={classes.BGCardUnitsLabel}>
                                Next fetch: {props.updateTime}
                            </span>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </div>
    );
}
