//https://gist.github.com/StephenBlackWasAlreadyTaken/adb0525344bedade1e25
//https://github.com/gagebenne/pydexcom

//https://github.com/gagebenne/pydexcom#why-not-use-the-official-dexcom-developer-api

// Use official API for reporting
// User Share API for realtime.
// https://developer.dexcom.com/support/faq

//https://github.com/gagebenne/pydexcom/blob/main/pydexcom/const.py

import axios from 'axios';
import dexcomConfig from './dexcom-config';
const {
    authEndPoint,
    loginEndpoint,
    latestGlucoseEndpoint,
    applicationId
} = dexcomConfig;

let DEXCOM_API_HOST;

export const getAuth = async (req) => {
    const { dexcomUser, dexcomPassword } = req;

    const authHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    };

    const authBody = {
        accountName: dexcomUser,
        password: dexcomPassword,
        applicationId: applicationId
    };

    const reqOptions = {
        baseURL: DEXCOM_API_HOST,
        url: authEndPoint,
        method: 'POST',
        headers: authHeaders,
        data: authBody
    };

    try {
        const authResponse = await axios.default(reqOptions);
        if (authResponse.status !== 200) {
            console.log('Error logging into Dexcom!  Check your credentials. ');
            return;
        } else {
            return authResponse.data;
        }
    } catch (e) {
        console.log('Error logging into Dexcom!  Check your credentials. ');
        return;
    }
};

export const getLoginSession = async (req) => {
    const { dexcomPassword, authID } = req;

    if (!authID) {
        console.log('No auth ID provided.  Unable to get a session ID.');
        return;
    }
    const authHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    };

    const authBody = {
        accountId: authID,
        password: dexcomPassword,
        applicationId: applicationId
    };

    const reqOptions = {
        baseURL: DEXCOM_API_HOST,
        url: loginEndpoint,
        method: 'POST',
        headers: authHeaders,
        data: authBody
    };

    try {
        const authResponse = await axios.default(reqOptions);

        if (authResponse.status !== 200) {
            console.log(authResponse);
            throw new Error(
                'Error getting a Dexcom session ID: ',
                authResponse.statusText
            );
        }
        return authResponse.data;
    } catch (e) {
        console.log('Unable to get an authentication response from Dexcom!', e);
    }
};

export const getGlucoseEntries = async (req) => {
    const { minutes, maxCount, sessionID } = req;

    if (!sessionID) {
        console.log('No session ID provided.  Unable to get glucose entries.');
        return;
    }
    const reqHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    };

    const queryParams = {
        sessionID: sessionID,
        minutes: minutes ? minutes : 1440,
        maxCount: maxCount ? maxCount : 1
    };

    const reqOptions = {
        baseURL: DEXCOM_API_HOST,
        url: latestGlucoseEndpoint,
        method: 'POST',
        headers: reqHeaders,
        params: queryParams
    };

    try {
        const entriesResponse = await axios.default(reqOptions);

        if (entriesResponse.status !== 200) {
            return [];
        }
        return entriesResponse.data;
    } catch (e) {
        console.log('An error occurred interacting with Dexcom!', e);
        return [];
    }
};

export const writeGlucoseEntries = async (req) => {};

export const getDexcomEntries = async (req) => {
    const { BRIDGE_USER_NAME, BRIDGE_PASSWORD, BRIDGE_SERVER } = req;

    let entries = [];
    const credentails = {
        dexcomUser: BRIDGE_USER_NAME,
        dexcomPassword: BRIDGE_PASSWORD
    };

    if (BRIDGE_SERVER !== 'US') {
        DEXCOM_API_HOST = dexcomConfig.hosenameOUS;
    } else {
        DEXCOM_API_HOST = dexcomConfig.hostnameUS;
    }

    // Get session ID from the auth call.
    const authID = await getAuth(credentails);

    if (!authID) {
        console.log('Unable to get an auth ID from Dexcom!');
        return null;
    }
    //Get session ID from the login call.
    const sessionID = await getLoginSession({ ...credentails, authID });

    entries = await getGlucoseEntries({
        sessionID: sessionID,
        maxCount: 50
    });

    return entries;
};

export const getFormattedEntries = async (entries) => {
    let formattedData = [];

    if (entries?.length > 0) {
        formattedData = entries?.map((entry) => {
            const { WT, Value } = entry;
            let timestamp = new Date(parseInt(WT.substring(5, WT.length - 1)));

            let sgValue = parseInt(Value);

            return {
                x: timestamp,
                y: sgValue,
                raw: entry
            };
        });
    }

    return formattedData;
};
