import React, { useState } from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { SettingsApplications } from '@material-ui/icons';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-kit-pro-react/views/settingsPageStyle';

const useStyles = makeStyles(styles);

export default function DexcomSettings(props) {
    const classes = useStyles();

    const { handleContextUpdate, userPreferences } = props;

    const { nightscout } = userPreferences;

    const {
        BRIDGE_USER_NAME,
        BRIDGE_PASSWORD,
        BRIDGE_INTERVAL,
        BRIDGE_MAX_COUNT,
        BRIDGE_FIRST_FETCH_COUNT,
        BRIDGE_MAX_FAILURES,
        BRIDGE_MINUTES,
        BRIDGE_SERVER
    } = nightscout;

    const [dexcomSettings, setDexcomSettings] = useState({
        BRIDGE_USER_NAME: BRIDGE_USER_NAME || '',
        BRIDGE_PASSWORD: BRIDGE_PASSWORD || '',
        BRIDGE_INTERVAL: BRIDGE_INTERVAL || 150000,
        BRIDGE_MAX_COUNT: BRIDGE_MAX_COUNT || 1,
        BRIDGE_FIRST_FETCH_COUNT: BRIDGE_FIRST_FETCH_COUNT || 3,
        BRIDGE_MAX_FAILURES: BRIDGE_MAX_FAILURES || 3,
        BRIDGE_MINUTES: BRIDGE_MINUTES || 1400,
        BRIDGE_SERVER: BRIDGE_SERVER || 'US'
    });

    const [settingsApplied, setSettingsApplied] = useState(true);

    const handleTextInput = async (event) => {
        const { id, value } = event.target;
        setDexcomSettings({ ...dexcomSettings, [id]: value });
        setSettingsApplied(false);
    };

    const handleSelectInput = async (event) => {
        const { name, value } = event.target;
        setDexcomSettings({ ...dexcomSettings, [name]: value });
        setSettingsApplied(false);
    };

    const handleSettingsSubmit = async () => {
        handleContextUpdate('nightscout', { ...dexcomSettings });
        setSettingsApplied(true);
    };

    const DEXCOM_SERVER_OPTIONS = [
        { value: 'US', label: 'United States' },
        { value: 'EU', label: 'Europe' }
    ];

    const dexcomShareSettings = (
        <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    formControlProps={{ fullWidth: true }}
                    labelText="Bridge Username"
                    id="BRIDGE_USER_NAME"
                    name="BRIDGE_USER_NAME"
                    inputProps={{
                        type: 'text',
                        placeholder: 'Bridge Username',
                        value: dexcomSettings.BRIDGE_USER_NAME,
                        onChange: handleTextInput
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    formControlProps={{ fullWidth: true }}
                    labelText="Bridge Password"
                    id="BRIDGE_PASSWORD"
                    name="BRIDGE_PASSWORD"
                    inputProps={{
                        type: 'password',
                        placeholder: 'Bridge Password',
                        value: dexcomSettings.BRIDGE_PASSWORD,
                        onChange: handleTextInput
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <div>
                    <FormControl fullWidth className={classes.nsSettingSelect}>
                        <InputLabel htmlFor="bridge-server">
                            Bridge Server
                        </InputLabel>
                        <Select
                            id="BRIDGE_SERVER"
                            name="BRIDGE_SERVER"
                            value={dexcomSettings.BRIDGE_SERVER}
                            onChange={handleSelectInput}
                            inputProps={{
                                name: 'BRIDGE_SERVER',
                                id: 'BRIDGE_SERVER'
                            }}
                        >
                            {DEXCOM_SERVER_OPTIONS.map((serverOption) => {
                                return (
                                    <MenuItem
                                        value={serverOption.value}
                                        key={serverOption.label}
                                    >
                                        {serverOption.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </GridItem>
        </GridContainer>
    );

    const dexcomShareTab = {
        tabName: 'Share Settings',
        tabIcon: SettingsApplications,
        tabContent: dexcomShareSettings
    };

    return (
        <div>
            <CustomTabs
                title="Dexcom"
                headerColor="success"
                tabs={[dexcomShareTab]}
            />
            <Button
                color="info"
                size="lg"
                onClick={(e) => {
                    handleSettingsSubmit();
                }}
                disabled={settingsApplied}
                className={classes.saveButton}
            >
                Apply Settings{' '}
            </Button>
        </div>
    );
}
