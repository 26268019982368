import React, { useCallback, useEffect, useState } from 'react';

// @material-ui/core components
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import { makeStyles } from '@material-ui/core/styles';
import styles from './GlucoseGraphStyle.js';

// Victory chart components
import {
    VictoryAxis,
    VictoryScatter,
    VictoryChart,
    createContainer,
    VictoryLabel
} from 'victory';

// Serendipity Libs
import serendipityVictoryTheme from './serendipityVictoryTheme';
import { getDexcomEntries, getFormattedEntries } from 'lib/dexcom/index.js';

const useStyles = makeStyles(styles);

export default function EntriesGraph(props) {
    const classes = useStyles();

    const SerendipityVictoryContainer = createContainer('voronoi', 'cursor');

    const { loading, data } = props;

    return loading || data.length == 0 ? (
        <div
            className={
                classes.circularProgress + ' ' + classes.victoryContainer
            }
        >
            <Card>
                <CardBody
                    style={{
                        height: '400px'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%'
                        }}
                    >
                        <CircularProgress
                            className={classes.inlineBlock}
                            size={35}
                            thickness={4}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    ) : (
        <div style={{ width: '100%', height: '100%' }}>
            <Card>
                <CardHeader style={{ paddingBottom: '0' }}>
                    <div style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                        <span
                            style={{
                                textAlign: 'left',
                                fontSize: '20px',
                                fontWeight: '600'
                            }}
                        >
                            Glucose Level (mg/dL)
                        </span>

                        <div
                            style={{
                                float: 'right',
                                marginLeft: '15px',
                                marginTop: '-5px'
                            }}
                        ></div>
                        <div style={{ float: 'right', marginLeft: '5px' }}>
                            <span className={classes.legendDotRed} />
                            <span>Out of Range</span>
                        </div>
                        <div style={{ float: 'right', marginLeft: '5px' }}>
                            <span className={classes.legendDotGreen} />
                            <span>Target Range</span>
                        </div>
                    </div>
                </CardHeader>
                <div>
                    <VictoryChart
                        theme={serendipityVictoryTheme}
                        domain={{ y: [20, 500] }}
                        scale={{ x: 'time' }}
                        containerComponent={
                            <SerendipityVictoryContainer
                                cursorDimension="x"
                                cursorLabel={({ datum }) =>
                                    `Time: ${datum.x.getHours()}:${datum.x.getMinutes()}, SG: ${datum.y.toPrecision(
                                        5
                                    )}`
                                }
                                cursorLabelComponent={
                                    <VictoryLabel
                                        style={[
                                            { fontSize: 20, fontWeight: 300 }
                                        ]}
                                    />
                                }
                            />
                        }
                    >
                        <VictoryAxis />
                        <VictoryAxis dependentAxis />
                        <VictoryScatter
                            data={props.data}
                            x={(data) => data.x}
                            y="y"
                            // size={1.5}
                            size={({ active }) => (active ? 5 : 2)}
                            style={{
                                data: {
                                    fill: ({ datum }) => {
                                        if (datum.y <= props.targetLow) {
                                            return '#FC6045';
                                        } else if (
                                            datum.y >= props.targetHigh
                                        ) {
                                            return '#FC6045';
                                        } else {
                                            return '#0DBB53';
                                        }
                                    }
                                }
                            }}
                        />
                    </VictoryChart>
                </div>
                {/* <CardBody style={{ height: '350px' }}>
                </CardBody> */}
            </Card>
        </div>
    );
}
