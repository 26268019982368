const dexcomConfig = {
    hostnameUS: 'https://share2.dexcom.com',
    hosenameOUS: 'https://shareous1.dexcom.com',
    loginEndpoint:
        'ShareWebServices/Services/General/LoginPublisherAccountById',
    authEndPoint:
        'ShareWebServices/Services/General/AuthenticatePublisherAccount',
    latestGlucoseEndpoint:
        'ShareWebServices/Services/Publisher/ReadPublisherLatestGlucoseValues',
    applicationId: 'd89443d2-327c-4a6f-89e5-496bbb0317db',
    userAgent: 'Dexcom Share/3.0.2.11 CFNetwork/711.2.23 Darwin/14.0.0'
};

module.exports = dexcomConfig;
