import {
    successColor,
    tooltip,
    cardTitle,
    grayColor,
    chartColor
} from 'assets/jss/serendipity-theme';

import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js';

const dashboardStyle = {
    ...hoverCardStyle,
    tooltip,
    centerCellText: {
        textAlign: 'center'
    },
    cardTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px'
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    },
    cardProductTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px',
        textAlign: 'center'
    },
    cardCategory: {
        color: grayColor[0],
        fontSize: '14px',
        paddingTop: '10px',
        marginBottom: '0',
        marginTop: '0',
        margin: '0'
    },
    cardProductDesciprion: {
        textAlign: 'center',
        color: grayColor[0]
    },
    cardBodyCenterText: {
        textAlign: 'center'
    },
    stats: {
        color: grayColor[0],
        fontSize: '12px',
        lineHeight: '22px',
        display: 'inline-flex',
        '& svg': {
            position: 'relative',
            top: '4px',
            width: '16px',
            height: '16px',
            marginRight: '3px'
        },
        '& .fab,& .fas,& .far,& .fal,& .material-icons': {
            position: 'relative',
            top: '4px',
            fontSize: '16px',
            marginRight: '3px'
        }
    },
    productStats: {
        paddingTop: '7px',
        paddingBottom: '7px',
        margin: '0'
    },
    successText: {
        color: successColor[0]
    },
    upArrowCardCategory: {
        width: 14,
        height: 14
    },
    underChartIcons: {
        width: '17px',
        height: '17px'
    },
    price: {
        color: 'inherit',
        '& h4': {
            marginBottom: '0px',
            marginTop: '0px'
        }
    },
    cardFooterUpdateTime: {
        display: 'inline-flex'
    },

    timePicker: {
        padding: '0',
        paddingTop: '10px',
        margin: '0 15px 10px',
        borderRadius: '0',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
        border: '0'
    },
    terms: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '9'
    },
    legendDotRed: {
        height: '10px',
        width: '10px',
        backgroundColor: chartColor.chartRed,
        borderRadius: '50%',
        display: 'inline-block',
        marginLeft: '10px',
        marginRight: '10px'
    },
    legendDotGreen: {
        height: '10px',
        width: '10px',
        backgroundColor: chartColor.chartGreen,
        borderRadius: '50%',
        display: 'inline-block',
        marginLeft: '10px',
        marginRight: '10px'
    },
    BGCardIconContainer: {
        textAlign: 'center'
    },
    BGCardIcon: {
        height: '55px',
        width: '127px',
        backgroundColor: successColor[3],
        borderRadius: '10px',

        display: 'inline-block',
        marginLeft: '10px',
        marginRight: '10px',

        textAlign: 'center',
        padding: '12px',
        color: grayColor[0]
    },
    BGCardCurrentReading: {
        padding: '12px',
        display: 'block',
        fontFmily: 'Manrope',
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: '600',
        textAlign: 'center',
        marginTop: '10px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    BGCardUnitsLabel: {
        padding: '12px',
        display: 'block',
        fontFamily: 'Manrope',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
        marginLeft: '10px',
        marginRight: '10px'
    },
    BGCardReadingTrend: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
        height: '45px',
        width: '127px',
        backgroundColor: successColor[0],
        borderRadius: '10px',
        display: 'inline-block',
        marginLeft: '10px',
        marginRight: '10px',
        textAlign: 'center',
        padding: '12px',
        color: successColor[7]
    },
    BGCardVerticalContainer: {
        marginLeft: '10px',
        height: '100%',
        width: '15px'
    },
    BGCardVerticalBar: {
        transform: ({ value }) => {
            return `translateY(${value}%) !important`;
        }
    }
};

export default dashboardStyle;
