const API_ENV = process.env.REACT_APP_SERENDIPITY_API_ENV;

const getApiEnv = function () {
    switch (API_ENV) {
        case 'local':
            return '';
        case 'test':
            return 'https://api.test.serendipitybio.com';
        case 'prod':
            return 'https://api.prod.serendipitybio.com';
        default:
            return '';
    }
};

const getPaths = (paths) => {
    const apiEnv = getApiEnv();
    for (const path in paths) {
        paths[path] = apiEnv + paths[path];
    }
    return paths;
};

const getNsApiPaths = function () {
    const paths = {
        entries: '/api/v3/nightscout/entries',
        treatments: '/api/v3/nightscout/treatments',
        profile: '/api/v3/nightscout/profile',
        deviceStatus: '/api/v3/nightscout/devicestatus',
        updateSettings: '/api/v3/nightscout/settings',
        schema: '/api/v3/nightscout/settings/schema',
        restartNightscout: '/api/v3/nightscout/restart'
    };

    return getPaths(paths);
};

const getStripeApiPaths = function () {
    const paths = {
        createCheckoutSession: '/api/v3/payment/create-checkout-session',
        createFreeTierUser: '/api/v3/payment/create-free-tier-user',
        createPortalSession: '/api/v3/payment/create-portal-session',
        createSerendipityUserPath: '/api/v3/payment/create-serendipity-user'
    };

    return getPaths(paths);
};

const getStripeProduct = function () {
    const TEST_PRODUCTS = {
        NIGHTSCOUT_MONTHLY: 'price_1NMJxOJOnhSBuwAcoYxTlJEv',
        DEXCOM_LIVE_MONTHLY: ''
    };

    const PROD_PRODUCTS = {
        NIGHTSCOUT_MONTHLY: 'price_1Llc0pJOnhSBuwAcge3yaPQ7',
        DEXCOM_LIVE_MONTHLY: ''
    };

    switch (API_ENV) {
        case 'local':
            return TEST_PRODUCTS;
        case 'test':
            return TEST_PRODUCTS;
        case 'prod':
            return PROD_PRODUCTS;
        default:
            return TEST_PRODUCTS;
    }
};

const stripeApiPaths = {
    createCheckoutSessionPath: '/api/v3/payment/create-checkout-session',
    createFreeTierUserPath: '/api/v3/payment/create-free-tier-user',
    createPortalSessionPath: '/api/v3/payment/create-portal-session',
    createSerendipityUserPath: '/api/v3/payment/create-serendipity-user'
};

module.exports = {
    getNsApiPaths,
    getStripeApiPaths,
    getStripeProduct,
    stripeApiPaths
};
