import { assign } from 'lodash';

// *
// * Colors
// *
const colors = [
    '#252525',
    '#525252',
    '#737373',
    '#969696',
    '#bdbdbd',
    '#d9d9d9',
    '#f0f0f0'
];

// Serendipity colors

// Charting UI colors (light)
const chartYellow = '#feb82f';
const chartOrange = '#f98c2c';
const chartRed = '#fc6045';
const chartPink = '#e91f63';
const chartPurple = '#5627b0';
const chartBlue = '#262aaf';
const chartBlueLight = '#54c1fa';
const chartGreen = '#0dbb53';
const chartHighlightGreen = '#edfff4';
const chartHighlightRed = '#fff1ef';
const chartHighlightYellow = '#fff9ed';

const chartTextGrey = '#5f5f5f';
const chartLinesGrey = '#F1F1F1';
const chartTextLegend = '#111113';

//Victory colors
const blueGrey50 = '#ECEFF1';
const charcoal = '#252525';
const grey = '#969696';
// *

// *
// * Typography
// *
const sansSerif = "'Manrope', 'Helvetica', 'Arial', sans-serif";
const letterSpacing = 'normal';
const fontSize = 15;
// *
// * Layout
// *
const baseProps = {
    width: 1000,
    //height: 350,
    // padding: { top: 30, bottom: 40, left: 30, right: 80 },
    colorScale: colors
};
// *
// * Labels
// *
const baseLabelStyles = {
    fontFamily: sansSerif,
    fontSize,
    letterSpacing,
    padding: 10,
    fill: chartTextLegend,
    stroke: 'transparent'
};

const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles);
// *
// * Strokes
// *

// Stroke dash array x and y lengths
const strokeDasharray = '0, 1';
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

export default {
    area: assign(
        {
            style: {
                data: {
                    fill: charcoal
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    axis: assign(
        {
            style: {
                axis: {
                    fill: 'transparent',
                    stroke: chartLinesGrey,
                    strokeWidth: 1,
                    strokeLinecap,
                    strokeLinejoin
                },
                axisLabel: assign({}, centeredLabelStyles, {
                    padding: 20
                }),
                grid: {
                    fill: 'none',
                    stroke: 'none',
                    strokeDasharray,
                    strokeLinecap,
                    strokeLinejoin,
                    pointerEvents: 'painted'
                },
                ticks: {
                    fill: 'transparent',
                    size: 1,
                    stroke: 'transparent'
                },
                tickLabels: baseLabelStyles
            }
        },
        baseProps
    ),
    dependentAxis: assign(
        {
            style: {
                axis: {
                    fill: 'transparent',
                    stroke: chartLinesGrey,
                    strokeWidth: 2,
                    strokeLinecap,
                    strokeLinejoin
                },
                grid: {
                    fill: chartPurple,
                    stroke: chartLinesGrey,
                    strokeWidth: 2,
                    pointerEvents: 'painted'
                },
                labels: {
                    fill: 'none',
                    stroke: chartTextGrey,
                    fontSize: 10
                },
                tickLabels: baseLabelStyles
            }
        },
        baseProps
    ),
    bar: assign(
        {
            style: {
                data: {
                    fill: charcoal,
                    padding: 8,
                    strokeWidth: 0
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    boxplot: assign(
        {
            style: {
                max: { padding: 8, stroke: charcoal, strokeWidth: 1 },
                maxLabels: assign({}, baseLabelStyles, { padding: 3 }),
                median: { padding: 8, stroke: charcoal, strokeWidth: 1 },
                medianLabels: assign({}, baseLabelStyles, { padding: 3 }),
                min: { padding: 8, stroke: charcoal, strokeWidth: 1 },
                minLabels: assign({}, baseLabelStyles, { padding: 3 }),
                q1: { padding: 8, fill: grey },
                q1Labels: assign({}, baseLabelStyles, { padding: 3 }),
                q3: { padding: 8, fill: grey },
                q3Labels: assign({}, baseLabelStyles, { padding: 3 })
            },
            boxWidth: 20
        },
        baseProps
    ),
    candlestick: assign(
        {
            style: {
                data: {
                    stroke: charcoal,
                    strokeWidth: 1
                },
                labels: assign({}, baseLabelStyles, { padding: 5 })
            },
            candleColors: {
                positive: '#ffffff',
                negative: charcoal
            }
        },
        baseProps
    ),
    chart: baseProps,
    errorbar: assign(
        {
            borderWidth: 8,
            style: {
                data: {
                    fill: 'transparent',
                    stroke: charcoal,
                    strokeWidth: 2
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    group: assign(
        {
            colorScale: colors
        },
        baseProps
    ),
    histogram: assign(
        {
            style: {
                data: {
                    fill: grey,
                    stroke: charcoal,
                    strokeWidth: 2
                },
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    legend: {
        colorScale: [chartRed, chartGreen],
        gutter: 10,
        orientation: 'horizontal',
        titleOrientation: 'top',
        style: {
            data: {
                type: 'circle'
            },
            labels: baseLabelStyles,
            title: assign({}, baseLabelStyles, { padding: 5 })
        },
        padding: { right: 15 },
        x: 700
    },
    line: assign(
        {
            style: {
                stroke: chartLinesGrey,
                data: {
                    //     fill: 'transparent',
                    //     stroke: '#ffffff',
                },
                stroke: chartLinesGrey,
                strokeWidth: 4.5,
                labels: baseLabelStyles
            }
        },
        baseProps
    ),
    pie: {
        style: {
            data: {
                padding: 10,
                stroke: 'transparent',
                strokeWidth: 1
            },
            labels: assign({}, baseLabelStyles, { padding: 20 })
        },
        colorScale: colors,
        width: 400,
        height: 400,
        padding: 50
    },
    scatter: assign(
        {
            style: {
                data: {
                    // fill: '#ffffff',
                    // stroke: 'transparent',
                    // strokeWidth: 0
                },
                labels: baseLabelStyles
            }
        },

        baseProps
    ),
    stack: assign(
        {
            colorScale: colors
        },
        baseProps
    ),
    tooltip: {
        style: assign({}, baseLabelStyles, {
            padding: 0,
            pointerEvents: 'none'
        }),
        flyoutStyle: {
            stroke: charcoal,
            strokeWidth: 1,
            fill: '#f0f0f0',
            pointerEvents: 'none'
        },
        flyoutPadding: 5,
        cornerRadius: 5,
        pointerLength: 10
    },
    voronoi: assign(
        {
            style: {
                data: {
                    fill: 'transparent',
                    stroke: 'transparent',
                    strokeWidth: 0
                },
                labels: assign({}, baseLabelStyles, {
                    padding: 5,
                    pointerEvents: 'none'
                }),
                flyout: {
                    stroke: charcoal,
                    strokeWidth: 1,
                    fill: '#f0f0f0',
                    pointerEvents: 'none'
                }
            }
        },
        baseProps
    )
};
